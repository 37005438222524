@import "reset-css";

@import url("reset-css");

/* fonts */
@font-face {
  font-family: "Graphik";
  src: url("assets/fonts/Graphik-Light.woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Graphik";
  src: url("assets/fonts/Graphik-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Graphik";
  src: url("assets/fonts/Graphik-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Graphik";
  src: url("assets/fonts/Graphik-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Graphik";
  src: url("assets/fonts/Graphik-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

//  -----Default Styles-----
:root {
  --font-primary-color: #121316;
  --font-secondary-color: #787883;
  --font-tertiary-color: #363740;
  --font-white-color: #fff;
  --font-dark-color: #000;
  --error-color: #a31621;
  --font-family-graphik: "Graphik", Verdana, Tahoma, sans-serif;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: var(--font-family-graphik);
  font-size: 10px;
  font-weight: 400;
  color: var(--font-primary-color);
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: 1.6rem;
}

input {
  font-family: var(--font-family-graphik);
  padding: 0;
  background: none;
  border: none;
  outline: none !important;
  border-radius: 0px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
button {
  width: 100%;
  height: 4.6rem;
  padding: 0;
  font-family: var(--font-family-graphik);
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.015em;
  color: var(--font-white-color);
  background: none;
  border: none;
  cursor: pointer;
}
img {
  display: flex;
  width: 100%;
  height: auto;
}

.title {
  margin-bottom: 0.8rem;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.015em;
  color: var(--font-primary-color);
}
.subtitle {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.015em;
  color: var(--font-tertiary-color);
}
.description {
  font-size: 1.6rem;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: var(--font-secondary-color);
  span {
    font-weight: 500;
    color: #0f1011;
  }
}
.text {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.015em;
  color: var(--font-dark-color);
}

.follow-link {
  display: inline-block;
  padding: 1rem 2.25rem;
  border: 1px solid #363740;
  border-radius: 4px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: #363740;
}

.MuiAutocomplete-option {
  font-size: 1.4rem;
}

.extra-class {
  padding-bottom: 0 !important;
  height: 100vh !important;
  height: 100svh !important;
}

.centered {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-boundary {
  padding: 4rem 1.6rem 2rem;
  h3 {
    margin-bottom: 1.6rem;
    font-size: 3.6rem;
    font-weight: 400;
    color: #020202;
  }

  h4 {
    margin-bottom: 1.6rem;
    font-size: 2rem;
    font-weight: 500;
    color: #222;
  }

  p {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    color: #333;
  }
}
